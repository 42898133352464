.sidebar-container {
  padding: 10px 0px;
}
.price-filter__section {
  display: flex;
  flex-direction: column;
}
.price-filter__title {
  font-size: 30px;
  font-weight: 900;
  color: #2d85c5;
  margin-bottom: 15px;
}
.price-filter__input {
  padding: 0px 10px;
}
.price-ranges__section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.price-ranges__text {
  color: #2d85c5;
  font-size: 18px;
  font-weight: 900;
}
.price-ranges__button {
  background-color: #2d85c5;
  color: #fff;
  font-size: 16px;
  padding: 7px 18px;
  border-radius: 5px;
}

@media screen and (max-width: 768px) {
  .sidebar-container {
    margin-top: 20px;
  }
  .price-filter__title {
    font-size: 18px;
  }
  .price-ranges__text {
    font-size: 13px;
  }
  .price-ranges__button {

    font-size: 12px;
    padding: 8px 20px;

  }
  .category-filter__section {
    margin-top: 50px;
  }
}
