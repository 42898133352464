.card-item {
  background-color: #FDFDFD;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 60px;
  position: relative;
  border-bottom: 1px solid #999999;
}

.card-item-image {
  width: 184px;
  height: 184px;
  aspect-ratio: 1;
}

.card-item-image img {
  width: 100%;
  height: 100%;
}

.card-item-amount {
  color:#212121 ;
  font-size: 30px;
  font-weight: 900;
}

.card-item-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0px;
  width: 380px;
  max-width: 380px;
}

.card-item-name {
  color: #212121;
  font-size: 25px;
}
.card-item-quantity-buttons {
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: center; /* Centra los elementos horizontalmente */
  align-items: center;
}
.card-item-amount-button {
  font-size: 20px;
  font-weight: 900;
  color: #fff;
  background-color: #fac4ac;
  padding: 5px 0px;
  width: 89px;
  border-radius: 5px;
}
.card-item-amount-button.active {
  background-color: var(--primary-color);
}
.card-item-values {
  justify-self: flex-end;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.card-item-prices {
  display: flex;
  flex-direction: column;
  gap: 9px;
  align-items: flex-end;
}
.card-item-price {
  font-size: 23px;
  font-weight: 900;
  color: #e54242;
  text-decoration: line-through;
}
.card-item-discount {
  font-size: 20px;
  font-weight: 500;
  color: #212121;
}

.card-item-quantity {
  display: flex;
  width: 100%;
  justify-content: center; /* Centra los elementos horizontalmente */
  align-items: center;
}

.card-item-quantity button {

  font-size: 24px;
  color: #212121;
  background-color: var(--primary-color);
  padding-left:12px;
  width: 42px;
  height:42px;
  color:white;
  border-radius: 50px;
  justify-content: center; /* Centra los elementos horizontalmente */
  align-items: center;
}

.cart-item-input::-webkit-outer-spin-button,
.cart-item-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.cart-item-input{
  -moz-appearance: textfield;
}
.cart-item-input {
  text-align: center;
  width: 78px;
  height: 51px;
  font-size: 17px;
  font-weight: 700;
  color: #212121;
  background-color: #FDFDFD;
}
.card-item-input:focus,
.card-item-input:active,
.card-item-input:focus-visible {
  border: none;
  outline: none;
  box-shadow: none;
}

.card-item-remove {
  position: absolute;
  right: 20px;
  top: 15px;
  color: #707070;
  font-size: 25px;
}
.btn-remove-item > svg > path {
  stroke: #707070;
}

@media screen and (max-width: 850px) {
  .card-item{
    padding: 10px;
    justify-content: space-around;
  }

  .card-item-remove{
    position: absolute;
    right: 10px;
    top: -15px;
    color: #707070;
    font-size: 18px;
  }
  .card-item-image {
    width: 88px;
    height: 88px;
    aspect-ratio: 1;
  }
  .card-item-info{
    width: 143px;
    max-width: 143px;
    gap: 0px;
  }
  .card-item-name {

    font-size: 15px;
  }
  .card-item-quantity-buttons{
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center; /* Centra los elementos horizontalmente */
    align-items: center;
  }
  .card-item-amount-button{
    font-size: 12px;
    padding: 2px 0px;
    width: 44px;
  }
  .card-item-values{
    gap: 18px;
  } 
  .card-item-prices {
    gap: 0px;
  }
  .card-item-price{
    font-size: 15px;
  }
  .card-item-discount{
    font-size: 17px;
  }
  /* .card-item-quantity button{

    padding: 0px 3px;
    width: 32px;
    height: 32px;
    font-size: 24px;
    border-radius: 32px;
  } */
  .card-item-quantity button {

    font-size: 24px;
    color: #212121;
    background-color: var(--primary-color);
    width: 45px;
    height:45px;
    color:white;
    border-radius: 50px;
  }
  .cart-item-input{
    height: 36px;
    font-size: 16px;
    width: 45px;
  }
  .card-item-amount {
    font-size: 15px;
  }
}