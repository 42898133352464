.summaryInfo-container {
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  /* background-color: red; */
}
.summaryInfo-title {
  color: #12b918;
  font-size: 35px;
  font-weight: 700;
}

.summaryInfo-body {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-around;
  align-items: flex-start;
}

.summaryInfo-detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.summaryInfo-subtitle {
  color: #21AEE0;
  font-size: 20px;
  font-weight: 400;
}
.summaryInfo-content {
  color: #2D85C5;
  font-size: 25px;
  font-weight: 600;
  text-align: center;
  text-transform: capitalize;
}

.summaryInfo-footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 45px;
}
.summaryInfo-quantity{
  color: #2D85C5;
  font-size: 35px;
  font-weight: 600;
  
}
.summaryInfo-price-container{
  padding: 30px 30px;
  margin: 0 20px;
  border-top: 3px solid #297ABA;
  border-bottom: 3px solid #297ABA;
  max-width: 990px;
  width: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.summaryInfo-price-text{
  color:#2D85C5;
  font-size: 30px;
  font-weight: 900;
}

.summaryInfo-price-container2{
  margin: 0 20px;
  max-width: 990px;
  width: calc(100% - 40px);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.summaryInfo-discount-text{
  color:red;
  font-size: 30px;
  font-weight: 900;
}

.summaryInfo-buttons-container{
  margin: 0 20px;
  max-width: 990px;
  width: calc(100% - 40px);
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  gap: 35px;
}

.summaryInfo-btn{
  padding: 20px 0;
  font-size: 28px;
  color: white;
  font-weight: 900;
  border-radius: 5px;
  text-transform: uppercase;
}
.btn-backCart{
  background-color: #2D85C5;

}

.btn-finish{
  background-color: #21AEE0;
}
.summaryInfo-btn-mobile {
  display: none;
}

@media screen and (max-width: 850px) {
  .summaryInfo-container {
    margin-top: 30px;
    display: flex;
    position: relative;
    gap: 30px;
  }
  .summaryInfo-title {
    font-size: 20px;
  }
  .summaryInfo-body {
    grid-template-columns: 1fr;
    gap: 30px;
  }
  .summaryInfo-detail {
    margin: 0 20px;
    gap: 0px;
  }
  .summaryInfo-subtitle{

    font-size: 15px;
  }
  .summaryInfo-content{
    font-size: 15px;
    text-transform: capitalize;
  }
  .summaryInfo-footer{
    gap: 20px;
  }
  .summaryInfo-quantity{
    text-align: center;
    font-size: 15px;
  }
  .summaryInfo-price-container{
    padding: 20px 20px;
  }
  .summaryInfo-price-text{
    font-size: 20px;
  }

  .summaryInfo-price-container2{
    padding: 0px 0px;
  }
  .summaryInfo-discount-text{
    font-size: 20px;
  }
  .summaryInfo-buttons-container{
    display: none;
  }
  .summaryInfo-btn{

    font-size: 15px;
  }
  .btn-finish {
    position: fixed;
    bottom: 0;
    margin: 0 20px;
    width: calc(100% - 20px);
    z-index: 20;
  }
  .summaryInfo-btn-mobile {
    display: block;
  }
  
}
