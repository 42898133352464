.type-primary-state {
  align-items: center;
  background-color: var(--orangeorange-main-500);
  border-radius: 32px;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  padding: 8px 16px;
  position: relative;
}

.type-primary-state .button {
  color: var(--whitewhite-50);
  font-family: var(--body-body-bold-lg-font-family);
  font-size: var(--body-body-bold-lg-font-size);
  font-style: var(--body-body-bold-lg-font-style);
  font-weight: var(--body-body-bold-lg-font-weight);
  letter-spacing: var(--body-body-bold-lg-letter-spacing);
  line-height: var(--body-body-bold-lg-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}
