.l-container {
  /* background: yellow; */
  font-family: 'AmsiPro'!important;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.l-container-carrousel {
  /* background: yellow; */
  font-family: 'AmsiPro'!important;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  background-color: white;
}

@media screen and (min-width: 1024px) {
  .l-container {
    max-width: 1690px;
  }
  .l-container-carrousel {
    max-width: 1730px;
  }
}

.l-container--full {
  /* background: red; */
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
}
@media screen and (min-width: 1024px) {
  .l-container--full {
    max-width: 100%;
  }
}
@media screen and (max-width: 1024px) {
  .l-container--full {
    width: 100%;
  }
}
