.alert-default {
  align-items: center;
  background-color: var(--orangeorange-50);
  border-radius: 16px;
  display: inline-flex;
  gap: 8px;
  padding: 8px 16px;
  position: relative;
}

.alert-default .content {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
}

.alert-default .info {
  height: 36px;
  position: relative;
  width: 36px;
}

.alert-default .text {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  position: relative;
}

.alert-default .title {
  color: var(--orangeorange-main-500);
  font-family: var(--label-label-bold-lg-font-family);
  font-size: var(--label-label-bold-lg-font-size);
  font-style: var(--label-label-bold-lg-font-style);
  font-weight: var(--label-label-bold-lg-font-weight);
  letter-spacing: var(--label-label-bold-lg-letter-spacing);
  line-height: var(--label-label-bold-lg-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.alert-default .description {
  color: var(--orangeorange-main-500);
  font-family: var(--label-label-regular-lg-font-family);
  font-size: var(--label-label-regular-lg-font-size);
  font-style: var(--label-label-regular-lg-font-style);
  font-weight: var(--label-label-regular-lg-font-weight);
  letter-spacing: var(--label-label-regular-lg-letter-spacing);
  line-height: var(--label-label-regular-lg-line-height);
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.alert-default .close {
  height: 24px;
  position: relative;
  width: 24px;
}


.catlogo {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.catlogo .div-3 {
  background-color: #ffffff;
  height: 2129px;
  position: relative;
  width: 375px;
}

.catlogo .body {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 24px;
  left: 1px;
  position: absolute;
  top: 240px;
}

.catlogo .section {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  padding: 32px 16px;
  position: relative;
  width: 373px;
}

.catlogo .header {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
  width: 100%;
}

.catlogo .text-wrapper-2 {
  color: #000000;
  font-family: var(--title-title-bold-lg-font-family);
  font-size: var(--title-title-bold-lg-font-size);
  font-style: var(--title-title-bold-lg-font-style);
  font-weight: var(--title-title-bold-lg-font-weight);
  letter-spacing: var(--title-title-bold-lg-letter-spacing);
  line-height: var(--title-title-bold-lg-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.catlogo .p {
  align-self: stretch;
  color: #000000;
  font-family: var(--body-body-regular-md-font-family);
  font-size: var(--body-body-regular-md-font-size);
  font-style: var(--body-body-regular-md-font-style);
  font-weight: var(--body-body-regular-md-font-weight);
  letter-spacing: var(--body-body-regular-md-letter-spacing);
  line-height: var(--body-body-regular-md-line-height);
  position: relative;
  text-align: center;
}

.catlogo .row {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
  width: 100%;
}

.catlogo .card-timer-card {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  height: 500px;
  position: relative;
}

.catlogo .card {
  align-items: center;
  align-self: stretch;
  background-color: var(--whitewhite-50);
  border-radius: 8px 8px 0px 0px;
  box-shadow: var(--dark-shadow-300);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  padding: 8px 0px;
  position: relative;
  width: 100%;
}

.catlogo .tag-wrapper {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.catlogo .tag-instance {
  flex: 0 0 auto !important;
}

.catlogo .design-component-instance-node {
  font-family: "Figerona-Bold", Helvetica !important;
  font-size: 11px !important;
  font-style: unset !important;
  font-weight: 700 !important;
  letter-spacing: 0 !important;
  line-height: 16px !important;
}

.catlogo .product-image {
  height: 120px;
  object-fit: cover;
  position: relative;
  width: 120px;
}

.catlogo .info {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  padding: 0px 8px;
  position: relative;
  width: 100%;
}

.catlogo .block {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.catlogo .text-wrapper-3 {
  align-self: stretch;
  color: #000000;
  font-family: var(--title-title-bold-sm-font-family);
  font-size: var(--title-title-bold-sm-font-size);
  font-style: var(--title-title-bold-sm-font-style);
  font-weight: var(--title-title-bold-sm-font-weight);
  letter-spacing: var(--title-title-bold-sm-letter-spacing);
  line-height: var(--title-title-bold-sm-line-height);
  margin-top: -1px;
  position: relative;
}

.catlogo .price {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.catlogo .text-wrapper-4 {
  color: var(--orangeorange-main-500);
  font-family: var(--title-title-bold-sm-font-family);
  font-size: var(--title-title-bold-sm-font-size);
  font-style: var(--title-title-bold-sm-font-style);
  font-weight: var(--title-title-bold-sm-font-weight);
  letter-spacing: var(--title-title-bold-sm-letter-spacing);
  line-height: var(--title-title-bold-sm-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.catlogo .text-wrapper-5 {
  color: var(--greygrey-200);
  font-family: "Figerona-Regular", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 28px;
  position: relative;
  text-align: center;
  text-decoration: line-through;
  white-space: nowrap;
  width: fit-content;
}

.catlogo .alert-default {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--orangeorange-50);
  border-radius: 8px;
  display: flex;
  flex: 0 0 auto;
  gap: 4px;
  padding: 8px;
  position: relative;
  width: 100%;
}

.catlogo .content {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 8px;
  position: relative;
}

.catlogo .text {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  position: relative;
}

.catlogo .title {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4px;
  position: relative;
}

.catlogo .info-dp-fill {
  height: 16px;
  position: relative;
  width: 16px;
}

.catlogo .text-wrapper-6 {
  color: var(--orangeorange-main-500);
  font-family: "Figerona-Bold", Helvetica;
  font-size: 11px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.catlogo .text-wrapper-7 {
  align-self: stretch;
  color: var(--greygrey-300);
  font-family: var(--label-label-bold-sm-font-family);
  font-size: var(--label-label-bold-sm-font-size);
  font-style: var(--label-label-bold-sm-font-style);
  font-weight: var(--label-label-bold-sm-font-weight);
  letter-spacing: var(--label-label-bold-sm-letter-spacing);
  line-height: var(--label-label-bold-sm-line-height);
  position: relative;
}

.catlogo .line {
  align-self: stretch;
  height: 1px;
  object-fit: cover;
  position: relative;
  width: 100%;
}

.catlogo .images {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  opacity: 0;
  position: relative;
  width: 100%;
}

.catlogo .ellipse {
  height: 48px;
  margin-bottom: -373px;
  margin-left: -123px;
  object-fit: cover;
  position: relative;
  width: 48px;
}

.catlogo .div-wrapper {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--whitewhite-50);
  border-radius: 16px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
  width: 100%;
}

.catlogo .text-wrapper-8 {
  align-self: stretch;
  color: var(--greygrey-main-500);
  font-family: var(--label-label-bold-sm-font-family);
  font-size: var(--label-label-bold-sm-font-size);
  font-style: var(--label-label-bold-sm-font-style);
  font-weight: var(--label-label-bold-sm-font-weight);
  letter-spacing: var(--label-label-bold-sm-letter-spacing);
  line-height: var(--label-label-bold-sm-line-height);
  margin-top: -1px;
  position: relative;
}

.catlogo .design-component-instance-node-2 {
  align-self: stretch !important;
  display: flex !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.catlogo .type-primary-state-default-icon-none {
  font-family: var(--label-label-bold-md-font-family) !important;
  font-size: var(--label-label-bold-md-font-size) !important;
  font-style: var(--label-label-bold-md-font-style) !important;
  font-weight: var(--label-label-bold-md-font-weight) !important;
  letter-spacing: var(--label-label-bold-md-letter-spacing) !important;
  line-height: var(--label-label-bold-md-line-height) !important;
}

.catlogo .timer-instance {
  align-self: stretch !important;
  display: flex !important;
  flex: 0 0 auto !important;
  margin-bottom: -32px !important;
  width: 100% !important;
}

.catlogo .timer-2 {
  border-radius: 0px 0px 8px 8px !important;
  gap: 8px !important;
  padding: 4px 8px !important;
}

.catlogo .design-component-instance-node-3 {
  font-family: var(--label-label-bold-lg-font-family) !important;
  font-size: var(--label-label-bold-lg-font-size) !important;
  font-style: var(--label-label-bold-lg-font-style) !important;
  font-weight: var(--label-label-bold-lg-font-weight) !important;
  letter-spacing: var(--label-label-bold-lg-letter-spacing) !important;
  line-height: var(--label-label-bold-lg-line-height) !important;
}

.catlogo .timer-3 {
  font-family: var(--label-label-bold-sm-font-family) !important;
  font-size: var(--label-label-bold-sm-font-size) !important;
  font-style: var(--label-label-bold-sm-font-style) !important;
  font-weight: var(--label-label-bold-sm-font-weight) !important;
  letter-spacing: var(--label-label-bold-sm-letter-spacing) !important;
  line-height: var(--label-label-bold-sm-line-height) !important;
}

.catlogo .card-timer-card-2 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
}

.catlogo .images-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  position: relative;
  width: 100%;
}

.catlogo .img {
  height: 104px;
  margin-left: -24px;
  margin-top: -20px;
  object-fit: cover;
  position: relative;
  width: 96px;
}

.catlogo .card-2 {
  align-items: center;
  align-self: stretch;
  background-color: var(--whitewhite-50);
  border-radius: 8px 8px 0px 0px;
  box-shadow: var(--dark-shadow-300);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  margin-bottom: -8px;
  padding: 8px 0px;
  position: relative;
  width: 100%;
}

.catlogo .timer-4 {
  align-self: stretch !important;
  display: flex !important;
  flex: 0 0 auto !important;
  margin-bottom: -52px !important;
  width: 100% !important;
}

.catlogo .card-timer-card-3 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  height: 500px;
  opacity: 0;
  position: relative;
}

.catlogo .product-image-2 {
  height: 120px;
  margin-left: -267.75px;
  margin-top: -327px;
  object-fit: cover;
  position: relative;
  width: 120px;
}

.catlogo .info-2 {
  height: 16px;
  margin-left: -306.5px;
  margin-top: -639px;
  position: relative;
  width: 16px;
}

.catlogo .line-2 {
  align-self: stretch;
  height: 1px;
  margin-left: -290.5px;
  margin-top: -399px;
  object-fit: cover;
  position: relative;
  width: 100%;
}

.catlogo .ellipse-2 {
  height: 48px;
  margin-left: -298.5px;
  margin-top: -695px;
  object-fit: cover;
  position: relative;
  width: 48px;
}

.catlogo .app-bar-offer-instance {
  left: 0 !important;
  position: fixed !important;
  top: 2069px !important;
  width: 375px !important;
}

.catlogo .offer-2 {
  align-self: stretch !important;
  position: relative !important;
  width: 36px !important;
}

.catlogo .header-2 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  left: 0;
  position: fixed;
  top: 76px;
}

.catlogo .actions {
  align-items: flex-start;
  background-color: var(--whitewhite-50);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  padding: 8px 16px 16px;
  position: relative;
  width: 375px;
}

.catlogo .row-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.catlogo .col {
  align-items: center;
  display: flex;
  gap: 8px;
  position: relative;
  width: 199.5px;
}

.catlogo .icon-button-default-instance {
  background-image: url(https://c.animaapp.com/U9iyoaYU/img/menu.svg) !important;
  position: relative !important;
}

.catlogo .logo-instance {
  height: 19.96px !important;
  position: relative !important;
  width: 100px !important;
}

.catlogo .col-2 {
  align-items: center;
  display: flex;
  gap: 8px;
  position: relative;
  width: 80px;
}

.catlogo .icon-button-default-2 {
  background-image: url(https://c.animaapp.com/U9iyoaYU/img/user.svg) !important;
  position: relative !important;
}

.catlogo .icon-button-default-3 {
  background-image: url(https://c.animaapp.com/U9iyoaYU/img/cart-1.svg) !important;
  position: relative !important;
}

.catlogo .searchbar-default {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  position: relative;
  width: 343px;
}

.catlogo .input-container {
  align-items: center;
  border: 1px solid;
  border-color: var(--greygrey-200);
  border-radius: 32px 0px 0px 32px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 16px;
  padding: 8px 16px;
  position: relative;
}

.catlogo .text-wrapper-9 {
  color: var(--greygrey-200);
  flex: 1;
  font-family: var(--body-body-regular-md-font-family);
  font-size: var(--body-body-regular-md-font-size);
  font-style: var(--body-body-regular-md-font-style);
  font-weight: var(--body-body-regular-md-font-weight);
  letter-spacing: var(--body-body-regular-md-letter-spacing);
  line-height: var(--body-body-regular-md-line-height);
  margin-top: -1px;
  position: relative;
}

.catlogo .search-button-type {
  border-radius: 0px 32px 32px 0px !important;
  flex: 0 0 auto !important;
}

.catlogo .address {
  align-items: center;
  background-color: var(--orangeorange-50);
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 8px 20px;
  position: relative;
  width: 375px;
}

.catlogo .address-info {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
}

.catlogo .delivery-instance {
  height: 16px !important;
  margin-left: -3px !important;
  position: relative !important;
  width: 22.64px !important;
}

.catlogo .text-wrapper-10 {
  color: var(--orangeorange-main-500);
  font-family: var(--label-label-bold-lg-font-family);
  font-size: var(--label-label-bold-lg-font-size);
  font-style: var(--label-label-bold-lg-font-style);
  font-weight: var(--label-label-bold-lg-font-weight);
  letter-spacing: var(--label-label-bold-lg-letter-spacing);
  line-height: var(--label-label-bold-lg-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.catlogo .type-primary-state-default-icon-none-instance {
  flex: 0 0 auto !important;
  padding: 4px 16px !important;
}

.catlogo .top-banner-instance {
  left: 0 !important;
  position: fixed !important;
  top: 44px !important;
  width: 375px !important;
}

.catlogo .top-banner-2 {
  font-family: var(--body-body-bold-sm-font-family) !important;
  font-size: var(--body-body-bold-sm-font-size) !important;
  font-style: var(--body-body-bold-sm-font-style) !important;
  font-weight: var(--body-body-bold-sm-font-weight) !important;
  letter-spacing: var(--body-body-bold-sm-letter-spacing) !important;
  line-height: var(--body-body-bold-sm-line-height) !important;
}

.catlogo .status-bar-light {
  height: 449px;
  left: 0;
  position: fixed;
  top: -405px;
  width: 375px;
}

.catlogo .overlap-group {
  background-color: var(--greygrey-600);
  height: 44px;
  left: 0;
  position: absolute;
  top: 405px;
  width: 375px;
}

.catlogo .battery {
  height: 28px;
  left: 327px;
  position: absolute;
  top: 0;
  width: 24px;
}

.catlogo .wifi {
  height: 8px;
  left: 306px;
  position: absolute;
  top: 11px;
  width: 16px;
}

.catlogo .mobile-signal {
  height: 12px;
  left: 284px;
  position: absolute;
  top: 15px;
  width: 17px;
}

.catlogo .time {
  height: 11px;
  left: -57px;
  position: absolute;
  top: 1118px;
  width: 29px;
}

.catlogo .whatsapp {
  height: 56px;
  left: 303px;
  object-fit: cover;
  position: fixed;
  top: 707px;
  width: 56px;
}
