.formExperience-container {
  background-color: #f4f4f4;
  border-radius: 5px;
  padding: 40px;
}
.formExperience-question {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 40px;
}
.formExperience-title {
  color: #2d85c5;
  font-weight: 700;
  letter-spacing: 0;
  font-size: 25px;
}

.formExperience-emojis {
  display: flex;
  gap: 20px;
}

.formExperience-emojis .btn-emoji {
  border: none;
  outline: none;
  transition: ease 0.5s all;
  border-radius: 50%;
  overflow: hidden;
}
.formExperience-emojis .btn-emoji:hover {
  transform: scale(1.2);
}

.formExperience-emojis .btn-emoji.active {
  transform: scale(1.2);
}

.formExperience-select {
  background-color: transparent;
  border: 2px solid #2d85c5;
  padding: 15px 22px;
  border-radius: 5px;
  color: #2d85c5;
  font-size: 18px;
}

.formExperience-select:focus-visible {
  outline: none;
}
.formExperience-recommendation {
  background-color: transparent;
  border: 2px solid #2d85c5;
  border-radius: 5px;
  padding: 15px 22px;
  color: #2d85c5;
  resize: none;
  font-size: 18px;
}

.formExperience-recommendation::placeholder {
  color: #2d85c5;
}
.formExperience-recommendation:focus-visible {
  outline: none;
}

.formExperience-probability {
  display: flex;
  justify-content: space-between;
  row-gap: 10px;
  flex-wrap: wrap;
}

.btn-probability {
  width: 50px;
  padding: 4px 0px;
  border: 1px solid #2d85c5;
  color: #2d85c5;
  font-weight: 700;
  border-radius: 5px;
  background-color: transparent;
  font-size: 28px;
  transition: ease 0.3s all;
}

.btn-probability.active,
.btn-probability:hover {
  color: #fff;
  background-color: #2d85c5;
}

.formExperience-btn-submit {
  width: 100%;
  border-radius: 5px;
  background-color: #21aee0;
  color: #fff;
  text-transform: uppercase;
  padding: 15px 15px;
  font-size: 20px;
  font-weight: 700;
}

.formExperience-btn-submit:hover {
  opacity: 0.9;
}

@media screen and (max-width: 850px) {
  .formExperience-container {
    background-color: transparent;
    padding: 25px;
  }
  .formExperience-title {
    font-size: 15px;
  }
  .formExperience-emojis{
    gap: 10px;
  }
  .formExperience-emojis .btn-emoji{
    width: 44px;
    height: 44px;
  }
  .formExperience-question {
    margin-bottom: 40px;
  }
  .formExperience-select{
    font-size: 15px;
    padding: 6px 22px;
  }
  .formExperience-recommendation{
    font-size: 15px;
    padding: 10px 22px;
  }
  .btn-probability {
    font-size: 14px;
    width: 33px;
    padding: 6px 0px;
  }
  .formExperience-btn-submit{
    font-size: 15px;
  }
}