.search__container {
  display: flex;
  width: 100%;
  gap: 30px;
  margin-top: 100px;
}
.search__sidebar {
  width: 300px;
  /* border: 1px solid red; */
}
.search__sidebar--close {
  display: none;
}

@media screen and (max-width: 900px) {
  .search__container {
    position: relative;
    margin: 0px;
  }
  .search__sidebar {
    position: fixed;
    /* left: 0!important; */
    width: 293px;
    background-color: white;
    top: 0px;
    left: -330px;
    z-index: 1000;
    padding: 0px 24px;
    /* background-color: red; */
  }
  .search__sidebar--close{
    display: flex;
    justify-content: end;
    font-size: 30px;
    color: #2d85c5;
    cursor: pointer;
  }
  .search__sidebar.open {
    height: 100vh;
    width: 293px;
    top: 0;
    left: 0;
    overflow-y: auto;
  }
}
