.productoCard {
  /* border: 1px solid #ddd; */
  /* padding-top: 10px; */
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.productoCard__header {
  width: 100%;
  background-color: #fff;
  padding: 10px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.productoCard__img {
  width: auto;
  max-height: 120px;
}
.productoCard__body {
  flex-grow: 1;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 20px;
}
.productoCard__title {
  color: #005a87;
  font-weight: 600;
  font-size: 18px;
}
.strike{
    text-decoration: line-through;
    text-decoration-line: line-through;
    text-decoration-thickness: 2px;
    text-decoration-style: initial;
    text-decoration-color: #7f7f7f;
}
.productoCard__price {
  margin-top: 20px;
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 80px;
}
.productoCard__price p {
  font-weight: 900;
  color: #2d85c5;
  font-size: 24px;
}

.productoCard__price__withDescount {
  margin-top: 20px;
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 80px;
}
.productoCard__price__withDescount p {
  font-weight: 900;
  color: #7f7f7f;
  /* font-size: 24px; */
}
/* .productoCard__price p:first-child {
  font-weight: 900;
  color: #2d85c5;
  font-size: 24px;
} */
/* .productoCard__price p:last-child {
  text-decoration: line-through;
  color: #e54242;
  font-size: 17px;
} */

.productoCard__product {
  color: #2d85c5;
  font-size: 18px;
  font-weight: 600;
}
.productoCard__footer {
  width: 100%;
}
.productoCard__footer--button {
  padding: 10px;
  font-weight: 900;
  font-size: 12px;
  background-color: var(--primary-color);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  /* width: 100%; */
  border: none;
  border-radius: 32px;  
  margin-bottom: 5px;
  margin-right: 10px;
  margin-left: 10px;
}
.productoCard__footer--button[disabled] {
  background-color: #ddd;
  cursor: not-allowed;
}
.productoCard__footer--button[disabled] p {
  color: #000;
}
.productoCard__footer--button__text {
  color: #fff;
  margin-right: 10px;
}
.productoCard__footer--button__icon {
  color: #fff;
  font-size: 20px;
}
.productoCard__add {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  background-color: #fff;
  margin-bottom: 5px;
  margin-right: 10px;
  margin-left: 10px;
}
.productoCard__add--minus,
.productoCard__add--plus {
  background-color: var(--primary-color);
  width: 48px;
  height: 48px;
  padding: 10px;
  border-radius: 9999px;
  border: '1.20px var(--primary-color) solid';
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border: none;
  cursor: pointer;
}
.productoCard__add--input {
  /* padding: 10px; */
  /* border: 1px solid #eee; */
  height: 100%;
  width: 100%;
  align-content: center;
}
.productoCard__add--input input {
  border: none;
  outline: none;
  text-align: center;
  font-size: 18px;
  outline: none;
  border: none;
  width: 100%;
}

.productoGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-auto-flow: dense;
  gap: 5px;
}
@media screen and (max-width: 468px) {
  .productoGrid {
    grid-template-columns: repeat(2, 1fr);
  }
  .productoCard__header {
    padding: 10px 0px 0px 0px;
  }
  .productoCard__img {
    max-height: 85px;
  }
  .productoCard__price {
    margin-top: 10px;
    margin-bottom: 8px;
    gap: 60px;
  }
  .productoCard__price p{
    font-size: 17px;
  }
  /* .productoCard__price p:first-child {
    font-size: 17px;
  }
  .productoCard__price p:last-child {
    font-size: 12px;
  } */
  .productoCard__product {
    font-size: 13px;
  }
  .productoCard__footer--button{
    font-size: 12px;
  }
  .productoCard__add {
    height: 35px;    
  }
  .productoCard__add--minus,
  .productoCard__add--plus{
    width: 35px;
    height: 35px;
  }
}

.productoRow {
  grid-column: span 4;
}
@media screen and (max-width: 768px) {
  .productoRow {
    grid-column: span 2;
  }
}
