.promocion__container {
  flex-grow: 1;
  background-color: #fff;
}
.promocion__title {
  font-size: 40px;
  text-align: center;
  margin-top: 70px;
  margin-bottom: 60px;
  color: #000000;
  font-weight: 900;
}
.promocion {
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
  gap: 18px;
}
.promocion__item {
  /* max-width: 535px; */
  width: 100%;
  border-radius: 5px;
  /* background-color: #f4f4f4; */
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0px 0px;

}
.promocion__item--header {
  background-color: #005a87;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 10px;
  text-align: center;
}
.promocion__item--header p {
  color: #fff;
  font-size: 18px;
}
.promocion__item--body {
  /* background-color: #fff; */
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.12); 
  border-top-left-radius: 8px; 
  border-top-right-radius: 8px;
  /* padding: 10px 20px; */
}
/* .promocion__item--body__producto {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
} */
@media screen and (max-width: 468px) {
  .promocion__container {
    padding: 0px 21px;
  }
  .promocion {
    grid-template-columns: repeat(2, minmax(170px, 1fr));
  }
  .promocion__title {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .promocion__item {
    padding: 0px 0px;
  }
}
