.experience-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
  margin-top: 70px;
}

.experience-header{
  display: flex;
  flex-direction: column;
  gap: 60px;
  align-items: center;
}
.experience-title {
  color: #21AEE0;
  font-size: 40px;
  text-align: center;
  font-weight: 900;
}
.experience-image{
  width: 100%;
  max-width: 405px;
  margin: 0 auto;
}

@media screen and (max-width: 850px) {
  .experience-container{
    grid-template-columns: 1fr;
    margin-top: 30px;
    gap: 30px;
  }
  .experience-image {
    display: none;
  }
  .experience-title{
    font-size: 20px;
  }
}