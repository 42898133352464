.time {
  display: grid;
  grid-template-columns: repeat(4, 75px);
  justify-content: center;
  gap: 30px;
}

.time__container {
  margin-bottom: 26px;
}
.time__title {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  margin-bottom: 10px;
}
.time__title p {
  margin-left: 10px;
  font-size: 20px;
  color: #21aee0;
  font-weight: 600;
}
.time__title svg {
  fill: #21aee0;
  font-size: 18px;
}
.time__item {
  text-align: center;
  color: #21aee0;
  font-weight: 900;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.time__item--text {
  font-size: 10px;
  font-weight: normal;
  color: #2D85C5;
}
.time__item--hour {
  font-size: 36px;
  border: 1px solid #eee;
  background-color: #fff;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 20px 10px; */
}
.time__spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
}

@media screen and (max-width: 768px) {
  .time {
    grid-template-columns: repeat(4, 65px);
    gap: 30px;
  }
  .time__item {
    gap: 10px;
  }
  .time__item--hour {
    font-size: 36px;
    border: 1px solid #eee;
    background-color: #fff;
    height: 60px;
    /* padding: 20px 10px; */
  }
  .time__container {
    margin-bottom: 0px;
  }
}