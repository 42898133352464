.finish-container {
  height: calc(100vh - 182px);
}

.finish-loading {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.finish-loading > div {
  width: 700px;
}

.finish-card-container {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}
.check-confirmation > div {
  width: 192px;
}

.finish-card-title {
  color: #12b918;
  font-size: 40px;
  font-weight: 700;
}

.finish-card-body {
  margin-top: 20px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
}
.finish-card-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.finish-card-label {
  color: #21aee0;
  font-size: 21px;
  text-align: center;
}
.finish-card-number,
.finish-card-text {
  color: #2d85c5;
  font-size: 30px;
  text-transform: capitalize;
  font-weight: 700;
}
.finish-card-rating {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.finish-rating-size {
  font-size: 64px !important;
}
.finish-card-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 90px;
}

.finish-card-btn {
  font-size: 22px;
  color: #2d85c5;
  text-transform: uppercase;
  padding: 8px 20px;
  border: 1px solid #2d85c5;
  border-radius: 5px;
  font-weight: 900;
}

.finish-card-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.finish-card-social-text {
  color: #2d85c5;
  font-size: 26px;
  font-weight: 900;
}
.finish-card-social {
  display: flex;
  align-items: center;
  gap: 40px;
}

@media screen and (max-width: 850px) {
  .finish-card-container{
    gap: 20px;
  }
  .check-confirmation > div {
    width: 126px;
  }
  .finish-card-title{
    font-size: 35px;
  }
  .finish-card-body {
    margin-top: 10px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    gap: 10px;
  }
  .finish-card-label {
    font-size: 15px;
  }
  .finish-card-number{
    font-size: 18px;
  }
  .finish-card-text{
    font-size: 25px;
  }
  .finish-card-rating{
    margin-top: 20px;
  }
  .finish-rating-size {
    font-size: 64px !important;
  }
  .finish-card-btns{
    gap: 18px;
  }
  .finish-card-btn {
    font-size: 13px;
    padding: 10px 22px;
  }
  .finish-card-footer{
    flex-direction: column;
  }
  .finish-card-social-text{
    font-size: 18px;
  }
  .finish-card-social{
    gap: 50px;
  }
}