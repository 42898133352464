.mobile-tab-container {
  display: none;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  gap: 0px;
  margin-top: 2px;
  background-color: var(--primary-50-color);
}

.mobile-tab-btn {
  padding: 16px 0;
  background-color: var(--primary-50-color);
  border-radius: 0px;
  color: var(--primary-color);
  text-transform: uppercase;
  font-weight: 600;
  font-size: 13px;
}

.mobile-tab-btn.active {
  border-bottom: 3px solid var(--primary-color);
}

@media screen and (max-width: 850px) {
  .mobile-tab-container{
    display: grid;
  }
}