.faq-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  margin: 0 20px;
}
.title-page {
  color: var(--primary-color);
  font-weight: 900;
  font-size: 40px;
  margin-top: 50px;
}

@media screen and (max-width: 850px) {
  .faq-list {
    grid-template-columns: 1fr;
    gap: 10px;
  }
  .title-page{
    font-size: 20px;
    margin-top: 20px;
  }
}
