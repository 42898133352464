.productInfo-container {
  display: grid;
  grid-template-columns: 4.5fr 5.5fr;
}
.productInfo-header {
  display: flex;
  justify-content: center;
  align-items: center;
}
.productInfo-header img {
  width: 60%;
}
.productInfo-body {
  background-color: transparent;
  border-radius: 5px;
  padding: 40px 40px;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: left;
}

.productInfo-brand img {
  max-width: 100px;
}
.productInfo-name {
  margin-top: 30px;
  padding: 0 50px;
}
.productInfo-name p {
  color: #212121;
  font-size: 40px;
  font-weight: 900;
  text-transform: capitalize;
  text-align: center;
}
.productInfo-price {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 40px;
  margin-top: 20px;
  align-items: flex-start;
}
.price-label {
  color: #6A6A6A;
  font-weight: 100;
  font-size: 18px;
}
.price-venta {
  display: flex;
  flex-direction: column;
}
.price-venta > .price-label {
  text-align: center;
}
.price-venta-value-container{
  display: flex;
  gap: 20px;
}
.price-venta-value {

  color: var(--primary-color);
  font-weight: 900;
  font-size: 30px;
}
.price-sugprice-value {
  color: #6A6A6A;
  font-size: 30px;
}
.price-rentabilidad-value {
  color: #12b918;
  font-weight: 900;
  font-size: 25px;
}
.productInfo-quantity {
  display: flex;
  justify-content: center;
  gap: 60px;
  margin-top: 30px;
}
.quantity-button {
  font-size: 28px;
  font-weight: 900;
  padding: 2px;
  width: 116px;

  background-color: #ffdac9;
  color: #fff;
  border-radius: 5px;
}

.quantity-button.active {
  background-color: var(--primary-color);
}
.productInfo-button {
  margin-top: 30px;
}
.addcart-button {
  font-size: 18px;
  font-weight: 900;
  text-transform: uppercase;
  background-color: var(--primary-color);
  color: #fff;
  width: 100%;
  margin-Top : 10px;
  padding: 28px 113px;
  border-radius: 50px;
}
.addcart-button2 {
  font-size: 18px;
  font-weight: 900;
  text-transform: uppercase;
  background-color: var(--primary-color);
  color: #fff;
  width: 100%;
  margin-Top : 00px;
  padding: 28px 30px;
  border-radius: 50px;
}
.addcart-buttonWhite {
  font-weight: 900;
  font-size: 18px;
  margin-top: 00px;
  border-radius: 50px;
  width: 100%;
  background-color: #fff;
  color: var(--primary-color);
  padding: 25px 50px;
  border: 2px solid var(--primary-color);
  text-transform: none;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.addcart-buttonWhite2 {
  font-weight: 900;
  font-size: 18px;
  margin-top: 00px;
  margin-left: -10px;
  border-radius: 50px;
  width: 100%;
  background-color: #fff;
  color: var(--primary-color);
  padding: 6px 50px;
  border: 2px solid var(--primary-color);
  text-transform: none;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.buttonMore {
  font-weight: 600;
  font-size: 15px;
  margin-top: 0px;
  border-radius: 50px;
  width: 280px;
  background-color: #fff;
  color: var(--primary-color);
  padding: 15px 100px;
  border: 2px solid var(--primary-color);
  text-transform: none;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.addcart-quantity {
  display: flex;
}
.addcart__add--operator {
  padding: 25px;
  color: #fff;
  background-color: var(--primary-color);
  border-radius: 50px;
  height:70px
}
.addcart__input {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
}
.addcart__input input {
  padding: 32px;
  background-color: white;
  text-align: center;
  width: 100%;
  color: #212121;
  font-size: 20px;
}
.productInfo-footer {
  margin-top: 30px;
}
.footer-text {
  color: #212121;
  font-size: 25px;
  text-align: center;
}

.product__promos-title {
  margin-top: 40px;
  font-size: 35px;
  color: #212121;
  font-weight: 900;
}

.product__promos {

  margin-top: 20px;
  /* padding: 20px 0 20px 20px; */
}
.product-promo{
  margin-top: 20px;
  max-width: 525px;
}

.promotion__item {
  width: 100%;
  max-width: 525px;
  border-radius: 5px;
  background-color: #f4f4f4;
  padding: 30px;
  /* margin-right: 20px; */
}
.product__promos > .react-multi-carousel-track {
  display: grid;
  grid-template-columns: repeat(3, 535px);
  /* gap: 18px; */
}
.list-container {
  /* gap: 20px; */
  /* display: flex;
  flex-direction: column;
  justify-content: flex-end; */

}
.promotion__item--body {
  /* background-color: #fff; */
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  /* padding: 10px 20px; */
}

@media screen and (max-width: 768px) {
  .productInfo-container {
    padding: 0px 10px;
    grid-template-columns: minmax(300px, 1fr);
    gap: 10px;

    justify-items: center;
  }
  .productInfo-body {
    padding: 20px 10px;
  }
  .productInfo-brand img {
    max-width: 75px;
  }
  .productInfo-name {
    padding: 0px;
  }
  .productInfo-name p {
    font-size: 28px;
    font-weight: 900;
  }
  .productInfo-price {
    gap: 20px;
    margin-top: 20px;
  }
  .price-label {
    color: #6A6A6A;
    font-weight: 100;
    font-size: 15px;
  }
  .price-venta {
    display: flex;
    flex-direction: column;
  }
  .price-sugprice-value {
    font-size: 20px;
  }
  .price-venta-value-container{
    display: flex;
    gap: 8px;
  }
  .price-venta-value {
    color: var(--primary-color);
    font-size: 20px;
  }
  .price-rentabilidad-value {
    font-size: 14px;
  }
  .productInfo-quantity {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 30px;
  }
  .quantity-button {
    font-size: 15px;
    font-weight: 900;
    padding: 2px;
    width: 90px;

    background-color: #fadacb;
    color: #fff;
    border-radius: 5px;
  }
  .productInfo-button {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0 15px;
  }
  .addcart-button {
    font-size: 16px;
    width: 100%;
    padding: 15px 50px;
  }
  .addcart-button2 {
    font-size: 16px;
    width: 100%;
    padding: 15px 30px;
  }
  .addcart-buttonWhite {
    font-size: 16px;
    width: 100%;
    background-color: #fff;
    margin-top: 5px;
    color: var(--primary-color);
    padding: 3px 30px;
    border: 2px solid var(--primary-color);
    text-transform: none;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  .addcart-buttonWhite2 {
    font-size: 16px;
    width: 100%;
    background-color: #fff;
    margin-top: 10px;
    color: var(--primary-color);
    padding: 5px 43px;
    border: 2px solid var(--primary-color);
    text-transform: none;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  .addcart__add--operator {
    padding: 15px;
    color: #fff;
    background-color: var(--primary-color);
    border-radius: 30px;
    height:50px;
  }
  .addcart-quantity {
    display: flex;
    width: 100%;
  }
  .addcart__input {

    width: 100%;
  }
  .addcart__input input {
    padding: 12px;
    background-color: white;
    text-align: center;
    width: 100%;
    color: #212121;
    font-size: 20px;
  }
  .footer-text {
    font-size: 14px;
    text-align: center;
  }
  .product__promos > .react-multi-carousel-track {
    display: grid;
    grid-template-columns: repeat(3, 390px);
  }
  .product__promos-title {
    font-size: 25px;
  }
}
