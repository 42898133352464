.cart-container {
  margin-top: 20px;
  width: 100%;
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  gap: 15px;
}
.list-items {
  order: 0;
  /* background-color: blue; */
}
.resume-card {
  order: 1;

}

@media screen and (max-width: 1300px) {
  .cart-container{
    grid-template-columns: minmax(250px,1fr);
  }
  .list-items {
    order: 1;
  }
  .resume-card {
    padding: 0px 20px;
    order: 0;
  }
}
