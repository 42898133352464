.card-resume-container {
  background-color: #FDFDFD;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px;
  max-width: 100%;
  gap: 40px;
}
.card-title {
  color: #000000;
  font-weight: 900;
  font-size: 36px;
}
.card-subtitle {
  color: #000000;
  text-align: left;
  font-weight: 900;
  font-size: 25px;
}
.card-address {
  width: 100%;
  border: 1px solid #999999;
  border-radius: 5px;
  height: 64px;
  overflow: hidden;
  outline: none;
  color: #212121;
  font-weight: 400;

  /* appearance: none; */
  padding: 0px 20px;
}

.card-body {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.card-subtotal,
.card-discount,
.card-total {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #999999;
  padding-top: 10px;
}

.card-label-content {
  color: #212121;
  font-size: 16px;
  font-weight: 700;
}
.card-label-value {
  color: #999999;
  font-size: 16px;
  font-weight: 400;
}

.card-footer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.card-quantity {
  color: #212121;
  font-size: 15px;
  font-weight: 700;
}

.card-quantity-down {
  color: #f70505;
  font-size: 15px;
  font-weight: 600;
}

.card-back-store {
  color: #212121;
  text-decoration: underline;
  font-weight: 700;
  font-size: 15px;
}
.btn-start-order {
  color: #FDFDFD;
  background-color: var(--primary-color);
  padding: 8px 16px;
  font-size: 17px;
  font-weight: 900;
  text-transform: uppercase;
  border-radius: 32px;
  width: 100%;
  text-transform: none;
  height: 65px;
}

.btn-disabled {
  background-color: #707070;
}

@media screen and (max-width: 850px) {
  .card-resume-container {
    padding: 30px;
    gap: 20px;
  }
  .card-title {
    font-size: 20px;
  }
  .card-subtitle {
    color: #000000;
    text-align: left;
    font-weight: 900;
    font-size: 15px;
  }
  .card-address {
    font-size: 14px;
    height: 45px;
  }
  .card-subtotal,
  .card-discount,
  .card-total {
    padding-top: 5px;
  }
  .card-label-content {
    font-size: 14px;
  }
  .card-label-value {

    font-size: 14px;

  }
  .card-quantity {

    font-size: 12px;

  }
  .card-back-store {

    font-size: 17px;
  }
  .btn-start-order {
    padding: 8px 16px;
    font-size: 16px;
    width: 100%;
  }
  
}
