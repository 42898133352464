.search-button {
  align-items: flex-start;
  background-color: var(--orangeorange-main-500);
  border-radius: 0px 8px 8px 0px;
  display: inline-flex;
  gap: 10px;
  padding: 8px;
  position: relative;
}

.search-button .icon-end {
  height: 20px !important;
  position: relative !important;
  width: 20px !important;
}
