.title-noitems {
  color: #212121;
  font-weight: 900;
  font-size: 28px;
  text-align: center;
}
.cart-items {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 120px;
}
