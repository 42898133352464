.container {
  max-width: 1650px;
  margin: 10px auto;
  overflow: hidden;
  position: relative;
}

.container__slideshow {
  /* width: 100%; */
  display: flex;
  flex-wrap: nowrap;
  /* z-index: -1; */
}

.slide {
  min-width: 100%;
  overflow: hidden;
  transition: 0.3s ease all;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.slide__img {
  width: 100%;
  max-height: 400px;
  min-height: 112px;
  vertical-align: top;
  cursor: pointer;
}

.btn__banners {
  padding: 8px 16px;
  background-color: var(--primary-color);
  color:#fff;
  border-radius: 0px 0px 5px 5px;
  font-weight: 900;
  width: 100%;
}

.controls__button {
  background: rgba(172, 166, 166, 0.5);
  border: none;
  cursor: pointer;
  outline: none;
  width: 40px;
  height: 100%;
  text-align: center;
  position: absolute;
  transition: 0.3s ease all;
  font-size: 2.5em;
}

.controls__forward {

  /* width: 100%; */
  position: absolute;
  top: 0;
  height: 100%;
  right: 0;
}

.controls__back {
  position: absolute;
  top: 0;
  height: 100%;
  left: 0;
}
