.headerL {

  /* min-height: 100px; */
  display: flex;
  align-items: center;
  width: 100%;
  gap: 3.75rem;
  max-width: 1650px;
  margin: 0 auto;
  justify-content: space-around;
  align-items: flex-end;
  padding:30px 30px;
  /* justify-content: space-around; */
}
.headerL__container {
  width: 100%;
  position: relative;
  background-color: #FDFDFD;
}

.subheaderL {

  height: 70px;
  margin: 0 auto;
  max-width: 1700px;
  /* display: flex; */
  /* justify-content: space-around; */
}

.pinned {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 2;
  -webkit-backface-visibility: hidden;
}

.headerL__logo {
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-left: 25px; */
}

.headerL__ubicacion {
  display: flex;
  flex-basis: 300px;
  flex-shrink: 0;
}



.headerL__logo--img {
  /* width: 42px; */
  /* height: 33px; */
  margin: 0px 0px 0px 8px;
  /* margin-right: 20px; */
  cursor: pointer;

}
.headerL__logo--home {
  /* font-size: 32px; */
  /* margin-right: 10px; */
  color: #fff;
  cursor: pointer;
}
/* @media screen and (max-width: 768px) {
  .headerL__logo--home {
    font-size: 32px;
    order: 1;
  }
} */
.headerL__logo--search {
  /* order: 2; */
  /* font-size: 32px; */
  color: #fff;
  display: block;
}
@media screen and (max-width: 900px) {


  .headerL {
    min-height: 60px;
    width: calc(100% - 20px);
    padding: 0;
    /* margin: 0px 20px; */
    margin: 0px!important;
    padding: 0;
    padding-left: 20px;
    gap: 0px;
    align-items: center;
  }
  .headerL__logo {
    margin:0;
    /* flex-basis: 110px; */
    justify-content: start;
    flex: 1;
  }
  .headerL__logo--search {
    display: block;
    /* font-size: 32px; */
    /* margin-right: 33px; */
    /* order: 1; */
  }
  .subheaderL {
    background-color: var(--primary-50-color);
    height: 44px;
  }
  .header__ubicacion {
    display: none;
  }
}
.headerL__search {
  /* max-width: 60%; */
  display: block;
  flex-basis: 60%;
  flex-shrink: 4;

  position: relative;
}
input.eudistest {
  height: 20px; /* Establece la altura del input */

}
.Box__search {
  position: relative; /* Permite mover el input de su posición normal */
}

@media screen and (max-width: 900px) {
  .Box__search {
    top: 0px;
  }
}

.headerL__search-mobile {
  position: relative;
}
@media screen and (max-width: 900px) {
  .headerL__search {
    display: none;
  }
  .headerL__search__show {
    display: none;
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    z-index: 10;
    width: 100%;
  }
  .headerL__search__show .headerL__search--icon__lupa {
    color: #FDFDFD;
  }
}

.headerL__search--input {
  padding: 12px 12px 12px 55px;
  border: none;
  /* background-color: rgba(255, 255, 255, 0.15); */
  /* flex-shrink: ; */
  width: 100%;
  border-radius: 5px;
  outline: none;
  font-size: 14px;
}
.headerL__search--input::placeholder{
  color: #999999;
}
@media screen and (max-width: 900px) {
  .headerL__search--input {
    width: 100%;
    background-color: #FDFDFD;
    padding: 15px 15px 15px 15px;
    box-shadow: 1px 0px 5px 1px rgba(180, 180, 180, 0.5);
    border-radius: 0;
    z-index: 1000000;
  }
  .headerL__search--input::placeholder{
    color: #999999;
    opacity: 0.8;
  }
}
.headerL__search--icon__lupa,
.headerL__search--icon__close {
  position: absolute;
  top: 54%;
  transform: translateY(-50%);
  cursor: pointer;
  color: var(--primary-color);
}
.headerL__search--icon__lupa {
  left: 20px!important;
  background-color: var(--primary-color);
}
.headerL__search--icon__close {
  right: 50px;
}
.headerL__icons {
  display: flex;
  /* flex-basis: 200px; */
  min-width: 200px;
  justify-content: space-between;
  align-items: center;
  gap: 8px !important
}
.headerL__icons--item {
  cursor: pointer;
  /* font-size: 32px; */
  color: #fff;
}
@media screen and (max-width: 900px) {
  .headerL__icons {
    justify-content: end;
    min-width: 50px;
    flex: 1;
    margin-right: 0;
  }
  /* .headerL__icons--item {
    font-size: 32px;
  } */
}
.headerL__icons--item {
  margin-right: 0px;
}
.headerL__icons--item__cart {
  position: relative;
  cursor: pointer;
  /* margin-right: 20px; */
  display: flex;
  align-items: center;
  /* margin-right: 25px; */
}
.headerL__icons--item__cart svg {
  cursor: pointer;
  font-size: 32px;
  /* width: 48px; */
  color: #FDFDFD;
  background-color: var(--primary-color);
  border-radius: 18px;
  padding: 7px;
}

@media screen and (max-width: 768px) {
  .headerL__icons--item__cart svg {
    font-size: 48px;
    margin-right: 0px;
  }
}
.headerL__icons--item__cart p {
  position: absolute;
  top: -15px;
  right: -15px;
  background-color:#fff;
  color: #21AEE0;
  padding: 2px 6px;
  
  border-radius: 50%;
  font-size: 14px;
}
.headerL__prediction {
  background-color: #fff;
  box-shadow: 0px 0px 5px 1px rgba(180, 180, 180, 0.5);
  border-radius: 5px;
  position: absolute;
  width: 100%;
  top: 43px; /*LG*/
  max-height: 500px;
  overflow: auto;
  z-index: 10;
}
@media screen and (max-width: 900px) {
  .headerL__prediction {
    top: 35px;/*XS*/
  }
  .headerL__icons--item__cart p {
    top: -13px;
    right: -9px;
    font-size: 12px;
  }
}
.headerL__prediction--item {
  cursor: pointer;
  padding: 5px 10px 5px 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.headerL__prediction--item__promocion {
  position: absolute;
  top: 0;
  left: 0;
  background-color: yellow;
  padding: 5px;
}
.headerL__prediction--item__promocion svg {
  font-size: 12px;
}
.headerL__prediction--item__info {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .headerL__prediction--item__info {
    width: 100%;
  }
}
.headerL__prediction--item__info p {
  font-size: 14px;
  margin-left: 10px;
  color: #005a87;
}
.headerL__prediction--item__price {
  background-color: #005a87;
  padding: 5px 5px 5px, 5px;
  //width:70px;
  border-radius: 10px;
  text-align: right;
}
.headerL__prediction--item__price p {
  color: #fff;
  padding: 15px 5px 5px, 5px;
  width:70px;
  font-weight: 600;
  text-align: center;
  font-size: 12px;
}
.headerL__prediction--item:not(:last-child) {
  border-bottom: 1px solid #eee;
}
.headerL__prediction--item:hover {
  background-color: #eee;
}
.header__categories {
  position: relative;
}

.menu {
  position: absolute;
  /* right: -200%; */
  left: 0;
  top: 45px;
  visibility: hidden;
  /* background-color: white; */
  z-index: 10;
  width: 300px;
  box-shadow: 0px 0px 5px 1px rgba(180, 180, 180, 0.5);
  transition: all 0.5s ease-in-out;
}
@media screen and (max-width: 768px) {
  .menu {
    width: 100%;
  }
}
.menu__item {
  background-color: var(--primary-50-color);
  border-bottom: 1px solid white;


}
.menu.menu__show {
  right: 0;
  visibility: visible;
}
.menu__item:not(:last-child) {
  border-bottom: 1px solid #eee;
}
.menu__item:hover {
  background-color: hsl(197, 74%, 70%);
}
.menu__link {
  display: block;
  padding: 10px 20px;
  color: var(--primary-color);
}

.menu__nav-item {
  height: 23px;
  width: 100%;
  /* border-bottom: 1.5px solid #2D85C5; */
  color: gray;
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 0 20px;
  font-size: 12px;
  font-weight: bold;
}


.ubication__item {
  background-color: white;
  transition: background-color ease 0.5s;
  border-radius: 5px;
}
.ubication__item-active{
  background-color:#297ABA;
  transition: background-color ease 0.5s;
}
.ubication__address {
  color: #2D85C5;
  font-size: 20px;
  letter-spacing: 0;
  text-align: center;
}

.ubication__address-active{
  color: white;

}
.ubication__distrito {
  font-weight: bold;
  color: #2D85C5;
  font-size: 20px;
  text-align: center;
  letter-spacing: 0;
}

.ubication__distrito-active {
  color: white;
}

@media screen and (max-width: 900px){
  .ubication__address{
    font-size: 15px;
    text-align: left;
  }
  .ubication__distrito{
    font-size: 15px;
  }
}

