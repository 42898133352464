.timer {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.timer .div {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--greygrey-900);
  border-radius: 0px 0px 12px 12px;
  display: flex;
  flex: 0 0 auto;
  gap: 3px;
  justify-content: center;
  padding: 8px 24px;
  position: relative;
  width: 100%;
}

.timer .div-2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.timer .value {
  color: var(--whitewhite-50);
  font-family: var(--label-label-bold-sm-font-family);
  font-size: 14 px;
  font-style: var(--label-label-bold-sm-font-style);
  font-weight: 700;
  letter-spacing: var(--label-label-bold-sm-letter-spacing);
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.timer .label {
  color: var(--whitewhite-50);
  font-family: "Figerona", Helvetica;
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.timer .text-wrapper {
  color: var(--whitewhite-50);
  font-family: var(--label-label-bold-lg-font-family);
  font-size: var(--label-label-bold-lg-font-size);
  font-style: var(--label-label-bold-lg-font-style);
  font-weight: var(--label-label-bold-lg-font-weight);
  letter-spacing: var(--label-label-bold-lg-letter-spacing);
  line-height: var(--label-label-bold-lg-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}
