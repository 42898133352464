.app-bar-offer {
  align-items: flex-start;
  background-color: var(--whitewhite-50);
  border-color: var(--greygrey-100);
  border-top-style: solid;
  border-top-width: 1px;
  display: flex;
  gap: 32px;
  justify-content: center;
  padding: 8px 0px 16px;
  position: relative;
  width: 373px;
}

.app-bar-offer .icon-instance-node {
  height: 36px !important;
  position: relative !important;
  width: 36px !important;
}

.app-bar-offer .type-primary-state-default-icon-left {
  background-color: var(--orangeorange-100) !important;
  flex: 0 0 auto !important;
  gap: unset !important;
  padding: 8px !important;
}

.app-bar-offer .type-primary-state-instance {
  color: var(--orangeorange-main-500) !important;
  font-family: "Figerona-Bold", Helvetica !important;
  font-size: 11px !important;
  font-style: unset !important;
  font-weight: 700 !important;
  letter-spacing: 0 !important;
  line-height: 16px !important;
  margin-top: -1px !important;
}

.app-bar-offer .offer-instance {
  align-self: stretch !important;
  position: relative !important;
  width: 36px !important;
}
