.top-banner {
  align-items: center;
  background-color: var(--orangeorange-main-500);
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 8px 16px;
  position: relative;
  width: 1440px;
}

.top-banner .lorem-ipsum-dolor {
  color: #ffffff;
  font-family: var(--body-body-bold-lg-font-family);
  font-size: var(--body-body-bold-lg-font-size);
  font-style: var(--body-body-bold-lg-font-style);
  font-weight: var(--body-body-bold-lg-font-weight);
  letter-spacing: var(--body-body-bold-lg-letter-spacing);
  line-height: var(--body-body-bold-lg-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}
