.tag {
  align-items: center;
  background-color: var(--greygrey-main-500);
  border-radius: 0px 8px 8px 0px;
  display: inline-flex;
  gap: 10px;
  padding: 8px;
  position: relative;
}

.tag .lorem-ipsum {
  color: var(--whitewhite-50);
  font-family: var(--body-body-bold-lg-font-family);
  font-size: var(--body-body-bold-lg-font-size);
  font-style: var(--body-body-bold-lg-font-style);
  font-weight: var(--body-body-bold-lg-font-weight);
  letter-spacing: var(--body-body-bold-lg-letter-spacing);
  line-height: var(--body-body-bold-lg-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}
