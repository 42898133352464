.headRow th{
font-size: 25px;
color: #2D85C5;
font-weight: 700;
}

.bodyRow td{
    font-size: 25px;    
}

.nombrePerfil{
    color: #2d85c5;
    position: relative;
    font-size: 30px;
    font-weight: 900;
    text-align: center;
}

.backgroundIcono{
    background: #f4f4f4;
    position: relative;
    left: 50%;
    margin-left: -100px;
    width: 200px;
    height: 200px;
}

.iconosPrincipales{
    width: 90px;
    height: 90px;
}

.iconoTexto{
    width: 175px;
    background: #2d85c5;
    margin-left: 10px;
    margin-top: -65px;
    position: absolute;
    display: flex;
    justify-content: center;
    color:white;
    border-radius: 5px;
    cursor: pointer
}

.MuiSvgIcon{
    width: 200px !important;
    height: 200px !important;
    color: gray !important;
}

.fondoIconoNombre{
    display:flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    height: 100%;
    cursor: pointer;
}

.seleccionado{
    background: #21AEE0;
    color: #F4F4F4;    
}

.codigoCliente{
    color: #707070;
    position: relative;
    text-align: center;
    font-size: 22px;
    opacity: 1;
}

.textoCamara{
    width: 175px;
    background: #2d85c5;
    margin-left: 10px;
    margin-top: -65px;
    position: absolute;
    display: flex;
    justify-content: center;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}

.textoCambiarFoto{
    margin-left: 10px;
    font-size: 15px;
    font-weight: 700;
}
.mostrarWeb{
    display: block;
}

.mostrarMobile{
    display: none !important;
}

#noDesktop{
    display: none;
}

@media screen and (max-width: 768px) {
    .nombrePerfil {
        color: #2d85c5;
        position: relative;
        left: 50%;
        margin-left: -125px;
        font-size: 25px;
    }
    .codigoCliente {
        color: #21AEE0;
        position: relative;
        left: 50%;
        margin-left: -100px;
        font-size: 20px
    }

    .icono{
        width: 100px;
        height: 100px;
        color: gray;
    }
    .backgroundIcono{
        background: #f4f4f4;
        position: relative;
        left: 50%;
        margin-left: -70px;
        margin-top: 20px;
        width: 139px;
        height: 139px;        
    }

    #MuiSvgIcon{
        width: 160px;
        height: 160px ;
        color: gray;
        margin-left: -10px;
        margin-top: -10px;
    } 
    .iconoTexto{
        width: 118px;
        height: 22px;
        background: #2d85c5;
        margin-left: 15px;
        margin-top: -55px;
        position: absolute;
        display: flex;
        justify-content: center;
        color:white;
        border-radius: 5px;
        cursor: pointer

    }
    .textoCambiarFoto{
        margin-left: 4px;
        font-size: 12px;
        margin-top: 1px;
        font-weight: 700;
    }

    .mostrarWeb{
        display: none;
    }
    .mostrarMobile{
        display: block !important;
    }

    #noDesktop{
        display: block;
    }

    .direccionMobile{
        border-color: #052A5A;
        border-radius: 20px;
        border-width: 1px;
        margin-left: 50px;
        margin-top: 10px;
        width: 194px;
        height: 134px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 20px 0px
    }

    .textoTienda{
        color: #2d85C5;
        font-weight: 900;       
        font-size: 15px;         
        
    }
    .textoDireccion{
        font-weight: 100;
        color: #2d85C5;      
        font-size: 12px;
        padding-left: 10px;
        padding-right: 10px;
        text-align: center;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }

    .textoAgregarTienda{
        font-weight: lighter;
        color: white;     
    }

    #iconoTienda{
        color: #2D85C5;
        position: relative;
        margin-left: 80px;
        margin-top: 10px;
    }
    .agregarTienda{
        border-color: #052A5A;
        border-radius: 20px;
        border-width: 1px;
        margin-left: 50px;
        margin-top: 10px;
        width: 194px;
        height: 134px;
        background-color: #2D85C5;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    #iconoAgregar{
        color: white;
        position: relative;        
        height: 40px;
        width: 40px;
    }

    #iconoTienda{
        color: #2D85C5;
        position: relative;
        margin-left: 80px;
        margin-top: 10px;
    }
    .pedidoMobile{
        background: #f4f4f4;
        position: relative;        
        margin-left: 20px;
        width: 344px;
        height: 107px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
    }
    .textoFecha{        
        margin-left: 10px;
        color: #2d85C5;
        font-weight: 900;
        font-size: 15px;
    }
    .textoCompra{
        margin-left: 10px;
        color: #707070;       
        font-size: 11px;
    }

    
  }