.faq-box {
  width: 100%;
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  padding: 10px 20px;
  max-height: 62px;
  cursor: pointer;
  transition: max-height 0.15s ease-in;
  overflow: hidden;
}
.expanded {
  max-height: 300px;
  transition: max-height 0.15s ease-out;
}
.faq-collapse {
  max-height: 200px;
  overflow: auto;
}
.faq-question-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.faq-question {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--primary-color);
  font-size: 27px;
  max-width: 95%;
}

.faq-question.expanded-question {
  white-space: normal;
}

.faq-icon {
  width: 15px;
  height: 15px;
  border-bottom: 2px solid var(--primary-color);
  border-right: 2px solid var(--primary-color);
  transform: rotate(45deg);
}

.faq-answer {
  color: #2d85c5;
  font-size: 22px;
  text-transform: left;
  letter-spacing: 0;
}

.faq-icon.expanded-icon {
  transform: rotate(225deg);
}

@media screen and (max-width: 850px) {
  .faq-box {
    grid-template-columns: 1fr;
    padding: 5px 15px;
  }
  .faq-question {
    color: #2d85c5;
    font-size: 15px;
  }
  .faq-icon {
    width: 10px;
    height: 10px;
  }
  .faq-answer{
    font-size: 13px;
  }
}
