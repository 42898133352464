.summary-container {
  display: flex;
  flex-direction: column;
  gap: 100px;
}
.order-container{
  height: calc(100vh - 182px);
}

.order-loading {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}


.items-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  /* padding: 0px 200px; */
}
.items-title {
  color: #21aee0;
  font-size: 30px;
  
}

@media screen and (max-width: 850px) {
  .summary-container{
    gap: 35px;
  }
  .items-container {
    align-items: normal;
    gap: 10px;
    
  }

  .items-title {
    font-size: 15px;
    text-align: center;
  }
}
