@import url('https://fonts.googleapis.com/css2?family=Figerona:wght@400;700&display=swap');
/* body {
  	margin: 0; line-height: normal;
} */
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'AmsiPro';
  src: local('AmsiPro'), url("./fonts/AmsiPro/AmsiPro-Light.otf") format('opentype');
  font-weight: 100;
}

@font-face {
  font-family: 'AmsiPro';
  src: local('AmsiPro'), url("./fonts/AmsiPro/AmsiPro-Regular.otf") format('opentype');
}
@font-face {
  font-family: 'AmsiPro';
  src: local('AmsiPro'), url("./fonts/AmsiPro/AmsiPro-SemiBold.otf") format('opentype');
  font-weight: 600;
}
@font-face {
  font-family: 'AmsiPro';
  src: local('AmsiPro'), url("./fonts/AmsiPro/AmsiPro-Bold.otf") format('opentype');
  font-weight: 700;
}

@font-face {
  font-family: 'AmsiPro';
  src: local('AmsiPro'), url("./fonts/AmsiPro/AmsiPro-Ultra.otf") format('opentype');
  font-weight: 800;
}
@font-face {
  font-family: 'AmsiPro';
  src: local('AmsiPro'), url("./fonts/AmsiPro/AmsiPro-Black.otf") format('opentype');
  font-weight: 900;
}


body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'AmsiPro', sans-serif!important;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}


@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
} 

@-webkit-keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

.fadeIn {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;

  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

.xenioo-chat-widget {
  z-index: 1!important;
  left: 20px!important;
  bottom: 80px!important;
}
.xenioo-conversation {
  left: 72px!important;
  bottom: 80px!important;
}

.react-multiple-carousel__arrow{
  z-index: 1!important;
}
:root {
  --primary-color: #DA291C;
  --primary-50-color : #fff0f1;
  --primary-100-color : #fecfd2;

/*   
  --secondary-color: #666;
  --background-color: #f8f8f8; */


  --blueblue-100: rgba(176, 221, 255, 1);
  --blueblue-200: rgba(138, 204, 255, 1);
  --blueblue-300: rgba(84, 181, 255, 1);
  --blueblue-400: rgba(51, 166, 255, 1);
  --blueblue-50: rgba(230, 244, 255, 1);
  --blueblue-600: rgba(0, 131, 232, 1);
  --blueblue-700: rgba(0, 102, 181, 1);
  --blueblue-800: rgba(0, 79, 140, 1);
  --blueblue-900: rgba(0, 60, 107, 1);
  --blueblue-main-500: rgba(0, 144, 255, 1);
  --body-body-bold-lg-font-family: "Figerona", Helvetica;
  --body-body-bold-lg-font-size: 16px;
  --body-body-bold-lg-font-style: normal;
  --body-body-bold-lg-font-weight: 700;
  --body-body-bold-lg-letter-spacing: 0px;
  --body-body-bold-lg-line-height: 24px;
  --body-body-bold-md-font-family: "Figerona", Helvetica;
  --body-body-bold-md-font-size: 14px;
  --body-body-bold-md-font-style: normal;
  --body-body-bold-md-font-weight: 700;
  --body-body-bold-md-letter-spacing: 0px;
  --body-body-bold-md-line-height: 20px;
  --body-body-bold-sm-font-family: "Figerona", Helvetica;
  --body-body-bold-sm-font-size: 12px;
  --body-body-bold-sm-font-style: normal;
  --body-body-bold-sm-font-weight: 700;
  --body-body-bold-sm-letter-spacing: 0px;
  --body-body-bold-sm-line-height: 16px;
  --body-body-regular-lg-font-family: "Figerona", Helvetica;
  --body-body-regular-lg-font-size: 16px;
  --body-body-regular-lg-font-style: normal;
  --body-body-regular-lg-font-weight: 400;
  --body-body-regular-lg-letter-spacing: 0px;
  --body-body-regular-lg-line-height: 24px;
  --body-body-regular-md-font-family: "Figerona", Helvetica;
  --body-body-regular-md-font-size: 14px;
  --body-body-regular-md-font-style: normal;
  --body-body-regular-md-font-weight: 400;
  --body-body-regular-md-letter-spacing: 0px;
  --body-body-regular-md-line-height: 20px;
  --body-body-regular-sm-font-family: "Figerona", Helvetica;
  --body-body-regular-sm-font-size: 12px;
  --body-body-regular-sm-font-style: normal;
  --body-body-regular-sm-font-weight: 400;
  --body-body-regular-sm-letter-spacing: 0px;
  --body-body-regular-sm-line-height: 16px;
  --dark-shadow-100: 0px 3px 2px 0px rgba(0, 0, 0, 0.06);
  --dark-shadow-200: 0px 6px 12px 0px rgba(0, 0, 0, 0.06), 0px 4px 4px 0px rgba(0, 0, 0, 0.06);
  --dark-shadow-300: 0px 12px 24px 0px rgba(0, 0, 0, 0.06), 0px 4px 24px 0px rgba(0, 0, 0, 0.06);
  --dark-shadow-500: 0px 36px 60px 0px rgba(0, 0, 0, 0.1), 0px 4px 24px 0px rgba(0, 0, 0, 0.06);
  --display-display-bold-lg-font-family: "Figerona", Helvetica;
  --display-display-bold-lg-font-size: 57px;
  --display-display-bold-lg-font-style: normal;
  --display-display-bold-lg-font-weight: 700;
  --display-display-bold-lg-letter-spacing: 0px;
  --display-display-bold-lg-line-height: 64px;
  --display-display-bold-md-font-family: "Figerona", Helvetica;
  --display-display-bold-md-font-size: 45px;
  --display-display-bold-md-font-style: normal;
  --display-display-bold-md-font-weight: 700;
  --display-display-bold-md-letter-spacing: 0px;
  --display-display-bold-md-line-height: 52px;
  --display-display-bold-sm-font-family: "Figerona", Helvetica;
  --display-display-bold-sm-font-size: 36px;
  --display-display-bold-sm-font-style: normal;
  --display-display-bold-sm-font-weight: 700;
  --display-display-bold-sm-letter-spacing: 0px;
  --display-display-bold-sm-line-height: 44px;
  --display-display-regular-lg-font-family: "Figerona", Helvetica;
  --display-display-regular-lg-font-size: 57px;
  --display-display-regular-lg-font-style: normal;
  --display-display-regular-lg-font-weight: 400;
  --display-display-regular-lg-letter-spacing: 0px;
  --display-display-regular-lg-line-height: 64px;
  --display-display-regular-md-font-family: "Figerona", Helvetica;
  --display-display-regular-md-font-size: 45px;
  --display-display-regular-md-font-style: normal;
  --display-display-regular-md-font-weight: 400;
  --display-display-regular-md-letter-spacing: 0px;
  --display-display-regular-md-line-height: 52px;
  --display-display-regular-sm-font-family: "Figerona", Helvetica;
  --display-display-regular-sm-font-size: 36px;
  --display-display-regular-sm-font-style: normal;
  --display-display-regular-sm-font-weight: 400;
  --display-display-regular-sm-letter-spacing: 0px;
  --display-display-regular-sm-line-height: 44px;
  --greengreen-100: rgba(223, 249, 209, 1);
  --greengreen-200: rgba(208, 247, 186, 1);
  --greengreen-300: rgba(186, 243, 155, 1);
  --greengreen-400: rgba(173, 241, 136, 1);
  --greengreen-50: rgba(245, 253, 240, 1);
  --greengreen-500: rgba(152, 237, 106, 1);
  --greengreen-600: rgba(138, 216, 96, 1);
  --greengreen-700: rgba(108, 168, 75, 1);
  --greengreen-800: rgba(84, 130, 58, 1);
  --greengreen-900: rgba(64, 100, 45, 1);
  --greygrey-100: rgba(186, 186, 186, 1);
  --greygrey-200: rgba(153, 153, 153, 1);
  --greygrey-300: rgba(106, 106, 106, 1);
  --greygrey-400: rgba(77, 77, 77, 1);
  --greygrey-50: rgba(233, 233, 233, 1);
  --greygrey-600: rgba(30, 30, 30, 1);
  --greygrey-700: rgba(23, 23, 23, 1);
  --greygrey-800: rgba(18, 18, 18, 1);
  --greygrey-900: rgba(14, 14, 14, 1);
  --greygrey-main-500: rgba(33, 33, 33, 1);
  --headline-headline-bold-lg-font-family: "Figerona", Helvetica;
  --headline-headline-bold-lg-font-size: 32px;
  --headline-headline-bold-lg-font-style: normal;
  --headline-headline-bold-lg-font-weight: 700;
  --headline-headline-bold-lg-letter-spacing: 0px;
  --headline-headline-bold-lg-line-height: 40px;
  --headline-headline-bold-md-font-family: "Figerona", Helvetica;
  --headline-headline-bold-md-font-size: 28px;
  --headline-headline-bold-md-font-style: normal;
  --headline-headline-bold-md-font-weight: 700;
  --headline-headline-bold-md-letter-spacing: 0px;
  --headline-headline-bold-md-line-height: 36px;
  --headline-headline-bold-sm-font-family: "Figerona", Helvetica;
  --headline-headline-bold-sm-font-size: 24px;
  --headline-headline-bold-sm-font-style: normal;
  --headline-headline-bold-sm-font-weight: 700;
  --headline-headline-bold-sm-letter-spacing: 0px;
  --headline-headline-bold-sm-line-height: 32px;
  --headline-headline-regular-lg-font-family: "Figerona", Helvetica;
  --headline-headline-regular-lg-font-size: 32px;
  --headline-headline-regular-lg-font-style: normal;
  --headline-headline-regular-lg-font-weight: 400;
  --headline-headline-regular-lg-letter-spacing: 0px;
  --headline-headline-regular-lg-line-height: 40px;
  --headline-headline-regular-md-font-family: "Figerona", Helvetica;
  --headline-headline-regular-md-font-size: 28px;
  --headline-headline-regular-md-font-style: normal;
  --headline-headline-regular-md-font-weight: 400;
  --headline-headline-regular-md-letter-spacing: 0px;
  --headline-headline-regular-md-line-height: 36px;
  --headline-headline-regular-sm-font-family: "Figerona", Helvetica;
  --headline-headline-regular-sm-font-size: 24px;
  --headline-headline-regular-sm-font-style: normal;
  --headline-headline-regular-sm-font-weight: 400;
  --headline-headline-regular-sm-letter-spacing: 0px;
  --headline-headline-regular-sm-line-height: 32px;
  --label-label-bold-lg-font-family: "Figerona", Helvetica;
  --label-label-bold-lg-font-size: 14px;
  --label-label-bold-lg-font-style: normal;
  --label-label-bold-lg-font-weight: 700;
  --label-label-bold-lg-letter-spacing: 0px;
  --label-label-bold-lg-line-height: 20px;
  --label-label-bold-md-font-family: "Figerona", Helvetica;
  --label-label-bold-md-font-size: 12px;
  --label-label-bold-md-font-style: normal;
  --label-label-bold-md-font-weight: 700;
  --label-label-bold-md-letter-spacing: 0px;
  --label-label-bold-md-line-height: 16px;
  --label-label-bold-sm-font-family: "Figerona", Helvetica;
  --label-label-bold-sm-font-size: 11px;
  --label-label-bold-sm-font-style: normal;
  --label-label-bold-sm-font-weight: 400;
  --label-label-bold-sm-letter-spacing: 0px;
  --label-label-bold-sm-line-height: 16px;
  --label-label-regular-lg-font-family: "Figerona", Helvetica;
  --label-label-regular-lg-font-size: 14px;
  --label-label-regular-lg-font-style: normal;
  --label-label-regular-lg-font-weight: 400;
  --label-label-regular-lg-letter-spacing: 0px;
  --label-label-regular-lg-line-height: 20px;
  --label-label-regular-md-font-family: "Figerona", Helvetica;
  --label-label-regular-md-font-size: 12px;
  --label-label-regular-md-font-style: normal;
  --label-label-regular-md-font-weight: 400;
  --label-label-regular-md-letter-spacing: 0px;
  --label-label-regular-md-line-height: 16px;
  --orangeorange-100: rgba(255, 215, 196, 1);
  --orangeorange-200: rgba(255, 195, 168, 1);
  --orangeorange-300: rgba(255, 168, 128, 1);
  --orangeorange-400: rgba(255, 151, 103, 1);
  --orangeorange-50: rgba(255, 242, 236, 1);
  --orangeorange-600: rgba(232, 114, 59, 1);
  --orangeorange-700: rgba(181, 89, 46, 1);
  --orangeorange-800: rgba(140, 69, 36, 1);
  --orangeorange-900: rgba(107, 53, 27, 1);
  --orangeorange-main-500: rgba(255, 125, 65, 1);
  --redred-100: rgba(254, 207, 210, 1);
  --redred-200: rgba(254, 184, 189, 1);
  --redred-300: rgba(253, 151, 159, 1);
  --redred-400: rgba(253, 131, 140, 1);
  --redred-50: rgba(255, 240, 241, 1);
  --redred-500: rgba(252, 100, 111, 1);
  --redred-700: rgba(179, 71, 79, 1);
  --redred-800: rgba(139, 55, 61, 1);
  --redred-900: rgba(106, 42, 47, 1);
  --redred-main-600: rgba(229, 91, 101, 1);
  --title-title-bold-lg-font-family: "Figerona", Helvetica;
  --title-title-bold-lg-font-size: 22px;
  --title-title-bold-lg-font-style: normal;
  --title-title-bold-lg-font-weight: 700;
  --title-title-bold-lg-letter-spacing: 0px;
  --title-title-bold-lg-line-height: 28px;
  --title-title-bold-md-font-family: "Figerona", Helvetica;
  --title-title-bold-md-font-size: 16px;
  --title-title-bold-md-font-style: normal;
  --title-title-bold-md-font-weight: 700;
  --title-title-bold-md-letter-spacing: 0px;
  --title-title-bold-md-line-height: 24px;
  --title-title-bold-sm-font-family: "Figerona", Helvetica;
  --title-title-bold-sm-font-size: 14px;
  --title-title-bold-sm-font-style: normal;
  --title-title-bold-sm-font-weight: 700;
  --title-title-bold-sm-letter-spacing: 0px;
  --title-title-bold-sm-line-height: 20px;
  --title-title-regular-lg-font-family: "Figerona", Helvetica;
  --title-title-regular-lg-font-size: 22px;
  --title-title-regular-lg-font-style: normal;
  --title-title-regular-lg-font-weight: 400;
  --title-title-regular-lg-letter-spacing: 0px;
  --title-title-regular-lg-line-height: 28px;
  --title-title-regular-md-font-family: "Figerona", Helvetica;
  --title-title-regular-md-font-size: 16px;
  --title-title-regular-md-font-style: normal;
  --title-title-regular-md-font-weight: 400;
  --title-title-regular-md-letter-spacing: 0px;
  --title-title-regular-md-line-height: 24px;
  --title-title-regular-sm-font-family: "Figerona", Helvetica;
  --title-title-regular-sm-font-size: 14px;
  --title-title-regular-sm-font-style: normal;
  --title-title-regular-sm-font-weight: 400;
  --title-title-regular-sm-letter-spacing: 0px;
  --title-title-regular-sm-line-height: 20px;
  --whitewhite-100: rgba(248, 248, 248, 1);
  --whitewhite-200: rgba(244, 244, 244, 1);
  --whitewhite-300: rgba(240, 240, 240, 1);
  --whitewhite-400: rgba(237, 237, 237, 1);
  --whitewhite-50: rgba(253, 253, 253, 1);
  --whitewhite-500: rgba(232, 232, 232, 1);
  --whitewhite-600: rgba(211, 211, 211, 1);
  --whitewhite-700: rgba(165, 165, 165, 1);
  --whitewhite-800: rgba(128, 128, 128, 1);
  --whitewhite-900: rgba(97, 97, 97, 1);
  --yellowyellow-100: rgba(254, 240, 180, 1);
  --yellowyellow-200: rgba(254, 233, 143, 1);
  --yellowyellow-300: rgba(253, 224, 92, 1);
  --yellowyellow-400: rgba(253, 217, 61, 1);
  --yellowyellow-50: rgba(255, 250, 231, 1);
  --yellowyellow-600: rgba(229, 189, 11, 1);
  --yellowyellow-700: rgba(179, 148, 9, 1);
  --yellowyellow-800: rgba(139, 114, 7, 1);
  --yellowyellow-900: rgba(106, 87, 5, 1);
  --yellowyellow-main-500: rgba(252, 208, 12, 1);
}
