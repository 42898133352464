.type-primary-state-wrapper {
  align-items: center;
  background-color: var(--orangeorange-main-500);
  border-radius: 32px;
  display: inline-flex;
  gap: 4px;
  justify-content: center;
  padding: 8px 16px;
  position: relative;
}

.type-primary-state-wrapper .button-2 {
  color: var(--whitewhite-50);
  font-family: var(--body-body-bold-lg-font-family);
  font-size: var(--body-body-bold-lg-font-size);
  font-style: var(--body-body-bold-lg-font-style);
  font-weight: var(--body-body-bold-lg-font-weight);
  letter-spacing: var(--body-body-bold-lg-letter-spacing);
  line-height: var(--body-body-bold-lg-line-height);
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.type-primary-state-wrapper .type-base-10 {
  height: 36px !important;
  position: relative !important;
  width: 36px !important;
}
