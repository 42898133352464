.faq-list {
  display: grid;
  justify-content: center;
  width: 100%;
  grid-template-columns: repeat(2, minmax(325px, 675px));
  gap: 40px;
}

@media screen and (max-width: 850px) {
  .faq-list {
    grid-template-columns: 1fr;
    gap: 10px;
    margin: 0 20px;
  }
}
