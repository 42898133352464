
.video-wrapper {
  width: 100%;
  height: 100%;
  background-color: black;
  position: relative;
  aspect-ratio: 32/9;
  display: flex;
  justify-content: center;
  max-height: 400px;
}

.volume-control {
  position: absolute;
  bottom: 10px;
  right: 50px;
}
.btn-volume {
  color: black;
  padding: 5px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
}